// Components
import LazyProgressRaw, { lazyProgressStyler } from '../LazyProgressRaw';
import SearchBoxRaw, { searchBoxStyler } from '../SearchBoxRaw';
import StatItemRaw, { statItemStyler } from '../StatItemRaw';

// Utils
import { makeStyles, withStyles } from '../../utils';

export const stylesCreator = (theme) => ({
  Default: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
    },
    features: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
    },
    listTitle: {
      fontSize: 16,
    },
    paper: {
      minHeight: 835,
    },
    subtitle: {
      marginBottom: theme.spacing(2),
    },
    statsContainer: {
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  },
  LazyProgressRaw: lazyProgressStyler(theme).Default,
  SearchBoxRaw: {
    ...searchBoxStyler(theme).Default,
    root: {
      ...searchBoxStyler(theme).Default.root,
      alignSelf: 'center',
      width: '100%',
    },
  },
  StatItemRaw: statItemStyler(theme).Default,
});

// Local
export const useStyles = makeStyles(stylesCreator);

// HOCs
export const LazyProgress = withStyles(stylesCreator, LazyProgressRaw);
export const SearchBox = withStyles(stylesCreator, SearchBoxRaw);
export const StatItem = withStyles(stylesCreator, StatItemRaw);
