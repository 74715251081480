// Types
import * as types from './types';

// Create
export const createTokenStart = () => ({
  type: types.CREATE_TOKEN_START,
});

export const createTokenSuccess = (token) => ({
  type: types.CREATE_TOKEN_SUCCESS,
  payload: token,
});

export const createTokenFailure = (error) => ({
  type: types.CREATE_TOKEN_FAILURE,
  payload: error,
});

// Get
export const getTokenStart = () => ({
  type: types.GET_TOKEN_START,
});

export const getTokenSuccess = (token) => ({
  type: types.GET_TOKEN_SUCCESS,
  payload: token,
});

export const getTokenFailure = (error) => ({
  type: types.GET_TOKEN_FAILURE,
  payload: error,
});

// Reset Password
export const resetPasswordStart = () => ({
  type: types.RESET_PASSWORD_START,
});

export const resetPasswordSuccess = () => ({
  type: types.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = () => ({
  type: types.RESET_PASSWORD_FAILURE,
});

// Set Password
export const setPasswordStart = () => ({
  type: types.SET_PASSWORD_START,
});

export const setPasswordSuccess = () => ({
  type: types.SET_PASSWORD_SUCCESS,
});

export const setPasswordFailure = () => ({
  type: types.SET_PASSWORD_FAILURE,
});

// Set Redirection
export const setRedirect = (location) => ({
  type: types.SET_REDIRECT,
  payload: location,
});

// Reset Redirection
export const resetRedirect = () => ({
  type: types.RESET_REDIRECT,
});

// Reset
export const reset = () => ({
  type: types.RESET,
});
