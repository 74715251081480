// Axios
import axios from 'axios';

// Redux
import { batch } from 'react-redux';

// Lodash
import _ from 'lodash';

// Creators
import * as creators from './creators';

// Actions
import { showAlert } from '../';

// Create
export const createSubscription = (data) => {
  return (dispatch) => {
    dispatch(creators.createSubscriptionStart());
    axios
      .post('/subscriptions', data)
      .then((response) => {
        batch(() => {
          dispatch(creators.createSubscriptionSuccess(response.data.data));
          dispatch(showAlert('You are now subscribed', 'success'));
        });
      })
      .catch((error) => {
        batch(() => {
          dispatch(creators.createSubscriptionFailure(error));
          dispatch(showAlert(_.get(error, 'response.data.error')));
        });
      });
  };
};

// Get Subscription Plans
export const getSubscriptionPlans = () => {
  return (dispatch) => {
    dispatch(creators.getSubscriptionPlansStart());
    axios
      .get('/subscriptions/get-plans')
      .then((response) => {
        dispatch(creators.getSubscriptionPlansSuccess(response.data.data));
      })
      .catch((error) => {
        batch(() => {
          dispatch(creators.getSubscriptionPlansFailure(error));
          dispatch(showAlert(_.get(error, 'response.data.error')));
        });
      });
  };
};

// Get Subscriptions
export const getSubscriptions = () => {
  return (dispatch) => {
    dispatch(creators.getSubscriptionsStart());
    axios
      .get('/subscriptions?sort=-createdAt')
      .then((response) => {
        dispatch(creators.getSubscriptionsSuccess(response.data));
      })
      .catch((error) => {
        batch(() => {
          dispatch(creators.getSubscriptionsFailure(error));
          dispatch(showAlert(_.get(error, 'response.data.error')));
        });
      });
  };
};
