export const SEARCH_FILTER_ANY = {
  type: 'all',
  label: 'All',
};

export const SEARCH_FILTER = {
  blockchains: [
    SEARCH_FILTER_ANY,
    { type: 'eth', label: 'Ethereum' },
    { type: 'bsc', label: 'Binance' },
    { type: 'matic', label: 'Polygon' },
    { type: 'avax', label: ' Avalanche' },
    { type: 'ftm', label: ' Fantom' },
    { type: 'arb', label: ' Arbitrum' },
    { type: 'op', label: 'Optimism' },
    { type: 'glmr', label: 'Moonbeam' },
    { type: 'cro', label: 'Cronos' },
    { type: 'gnosis', label: 'Gnosis' },
    { type: 'movr', label: 'Moonriver' },
    { type: 'celo', label: 'Celo' },
  ],
  tokenStandards: [
    SEARCH_FILTER_ANY,
    { type: 'isErc20', label: 'ERC-20' },
    { type: 'isErc721', label: 'ERC-721' },
  ],
  securityScores: [
    SEARCH_FILTER_ANY,
    { type: 'low', label: 'Low' },
    { type: 'medium', label: 'Medium' },
    { type: 'high', label: 'High' },
  ],
};
