// React
import React from 'react';

// Axios
import axios from 'axios';

// Router
import { NavLink } from 'react-router-dom';

// Material
import { Code as ApiDocsIcon, LibraryBooks as TermsIcon } from '@material-ui/icons';
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';

// Styles
import { useStyles } from './MenuReferences-styles';

const MenuReferences = (props) => {
  // Variables
  const classes = useStyles();
  const { onClose } = props;
  const apiDocsLink = axios.defaults.baseURL + '/docs';

  //JSX
  const header = <ListSubheader className={classes.header}>References</ListSubheader>;

  const view = (
    <div className={classes.root}>
      <List className={classes.list} subheader={header}>
        <ListItem button component={NavLink} to="/terms" rel="noopener" onClick={onClose}>
          <ListItemIcon>
            <TermsIcon />
          </ListItemIcon>
          <ListItemText primary="Terms" />
        </ListItem>
        <ListItem button component="a" href={apiDocsLink} target="_blank" rel="noopener">
          <ListItemIcon>
            <ApiDocsIcon />
          </ListItemIcon>
          <ListItemText primary="API Docs" />
        </ListItem>
      </List>
    </div>
  );

  return view;
};

export default MenuReferences;
