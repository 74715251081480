import React from 'react';

// Icons
import {
  Arbitrum as ArbitrumIcon,
  Avalanche as AvalancheIcon,
  Binance as BinanceIcon,
  Celo as CeloIcon,
  Cronos as CronosIcon,
  Blockchain as DefaultIcon,
  Ethereum as EthereumIcon,
  Fantom as FantomIcon,
  Gnosis as GnosisIcon,
  Moonbeam as MoonbeamIcon,
  Moonriver as MoonriverIcon,
  Optimism as OptimismIcon,
  Polygon as PolygonIcon,
} from '../icons';

const BlockchainIcon = ({ symbol }) => {
  switch (symbol) {
    case 'eth':
      return <EthereumIcon fontSize="small" />;
    case 'bsc':
      return <BinanceIcon fontSize="small" />;
    case 'matic':
      return <PolygonIcon fontSize="small" />;
    case 'avax':
      return <AvalancheIcon fontSize="small" />;
    case 'ftm':
      return <FantomIcon fontSize="small" />;
    case 'arb':
      return <ArbitrumIcon fontSize="small" />;
    case 'op':
      return <OptimismIcon fontSize="small" />;
    case 'glmr':
      return <MoonbeamIcon fontSize="small" />;
    case 'cro':
      return <CronosIcon fontSize="small" />;
    case 'gnosis':
      return <GnosisIcon fontSize="small" />;
    case 'movr':
      return <MoonriverIcon fontSize="small" />;
    case 'celo':
      return <CeloIcon fontSize="small" />;
    default:
      return <DefaultIcon fontSize="small" />;
  }
};

export default BlockchainIcon;
