// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Celo = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="-100 -100 2100.000000 2100.000000">
      <g transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)">
        <path
          d="M11725 19989 c-1833 -96 -3508 -782 -4878 -1998 -821 -728 -1542
-1721 -1967 -2706 -24 -55 -48 -105 -54 -111 -6 -7 -58 -32 -116 -57 -58 -25
-184 -84 -280 -131 -1945 -947 -3440 -2691 -4074 -4751 -468 -1521 -471 -3129
-9 -4650 217 -718 547 -1414 973 -2055 1005 -1514 2526 -2652 4250 -3179 1212
-370 2508 -447 3746 -220 2371 434 4412 1928 5551 4064 83 156 221 443 268
555 15 36 32 70 38 76 7 6 73 37 147 69 182 80 501 242 690 350 1574 902 2787
2304 3445 3981 682 1737 722 3683 113 5452 -320 929 -822 1806 -1462 2553
-844 986 -1894 1737 -3094 2213 -1031 410 -2192 602 -3287 545z m875 -2115
c2299 -196 4266 -1743 4995 -3929 207 -620 298 -1180 298 -1840 0 -636 -88
-1195 -279 -1788 -277 -857 -743 -1621 -1383 -2270 -163 -166 -412 -395 -439
-404 -4 -2 -8 133 -8 300 -4 711 -93 1366 -279 2046 -469 1713 -1517 3229
-2964 4285 -1030 753 -2258 1254 -3506 1430 -406 57 -688 78 -1097 80 -164 0
-298 3 -298 5 0 16 183 216 374 407 223 223 412 389 621 546 886 664 1939
1057 3045 1138 300 22 620 20 920 -6z m-4210 -4210 c96 -8 253 -26 348 -39
2147 -309 3959 -1826 4647 -3890 207 -620 298 -1180 298 -1840 0 -429 -37
-793 -118 -1184 -53 -252 -35 -222 -151 -248 -507 -115 -1040 -165 -1534 -143
-261 11 -371 21 -605 55 -1072 154 -2045 591 -2900 1301 -167 139 -560 532
-699 699 -689 829 -1117 1760 -1286 2798 -80 491 -96 1038 -45 1535 18 172 74
516 113 687 l27 120 145 32 c569 127 1176 168 1760 117z m-4178 -1457 c-5
-179 12 -592 34 -827 171 -1838 967 -3539 2268 -4843 1318 -1321 3021 -2122
4880 -2292 234 -22 649 -38 819 -33 81 3 147 2 147 -2 0 -14 -236 -274 -376
-414 -889 -888 -2026 -1456 -3269 -1635 -371 -53 -913 -71 -1260 -42 -836 70
-1620 301 -2317 682 -1621 886 -2716 2458 -2977 4274 -74 517 -74 1123 0 1640
161 1122 641 2160 1393 3013 169 191 626 632 656 632 3 0 4 -69 2 -153z"
        />
      </g>
    </SvgIcon>
  );
};
