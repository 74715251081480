// Axios
import axios from 'axios';

// Creators
import * as creators from './creators';

export const getComputedData = () => {
  return (dispatch) => {
    dispatch(creators.getComputedDataStart());
    axios
      .get(`/computed-data`)
      .then((response) => {
        dispatch(creators.getComputedDataSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(creators.getComputedDataFailure(error));
      });
  };
};
