// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Fantom = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="0 0 512.000000 512.000000">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          d="M2433 5105 c-65 -18 -28 1 -768 -387 -335 -176 -625 -332 -644 -347
-19 -15 -42 -42 -52 -61 -18 -33 -19 -98 -19 -1748 0 -1690 0 -1713 20 -1752
11 -21 34 -50 52 -63 43 -32 1285 -684 1363 -715 79 -32 196 -40 287 -18 52
12 221 96 735 366 378 199 682 365 700 382 68 66 63 -81 63 1798 0 1879 5
1732 -63 1798 -50 48 -1352 729 -1429 747 -78 18 -177 18 -245 0z m861 -531
c355 -186 645 -343 645 -349 0 -5 -290 -162 -645 -349 -632 -332 -646 -339
-713 -344 -42 -3 -83 0 -107 8 -31 11 -1298 671 -1311 684 -4 4 1286 682 1312
689 11 3 54 5 96 3 l77 -3 646 -339z m-1678 -815 c253 -132 506 -266 562 -296
l104 -55 -559 -294 c-307 -161 -561 -294 -565 -294 -5 0 -8 266 -8 590 0 325
2 590 4 590 2 0 210 -108 462 -241z m2354 -354 l0 -594 -138 72 c-679 356
-992 522 -992 526 1 7 1110 590 1123 591 4 0 7 -268 7 -595z m-1510 -815 l0
-680 -62 31 c-128 65 -1223 641 -1230 647 -6 6 1266 681 1285 682 4 0 7 -306
7 -680z m854 340 c350 -183 636 -336 636 -340 -1 -3 -224 -122 -498 -265 -273
-143 -563 -295 -644 -338 l-148 -78 0 682 c0 402 4 679 9 677 5 -1 295 -154
645 -338z m-1454 -933 c278 -146 532 -273 564 -282 90 -26 242 -17 321 19 32
14 317 163 634 329 316 166 578 304 583 305 4 2 8 -327 8 -731 l0 -735 -630
-332 c-346 -182 -655 -340 -687 -352 -70 -24 -146 -21 -219 11 -27 12 -327
167 -666 344 l-618 322 0 738 0 739 103 -55 c56 -30 329 -174 607 -320z"
        />
        <path
          d="M4107 4739 c-26 -44 -45 -84 -43 -88 3 -4 42 -30 88 -58 99 -61 158
-117 187 -177 23 -49 41 -146 41 -218 l0 -48 102 0 101 0 -6 95 c-14 205 -75
336 -204 436 -53 42 -204 139 -214 139 -2 0 -25 -37 -52 -81z"
        />
        <path
          d="M544 867 c12 -180 55 -287 157 -387 55 -55 199 -156 249 -175 11 -4
29 18 64 76 26 44 44 84 40 88 -5 4 -55 38 -111 74 -150 97 -186 157 -200 334
l-6 83 -100 0 -100 0 7 -93z"
        />
      </g>
    </SvgIcon>
  );
};
