// Types
import * as types from './types';

// Show
export const showFeedbackDialog = () => ({
  type: types.SHOW_FEEDBACK_DIALOG,
});

export const showPromptDialog = () => ({
  type: types.SHOW_PROMPT_DIALOG,
});

export const showAuditDialog = (initData = { blockchain: '', source: '', data: '' }) => ({
  type: types.SHOW_AUDIT_DIALOG,
  payload: initData,
});

export const showContractDialog = (initData = { blockchain: '', source: '', data: '' }) => ({
  type: types.SHOW_CONTRACT_DIALOG,
  payload: initData,
});

// Hide
export const hideFeedbackDialog = () => ({
  type: types.HIDE_FEEDBACK_DIALOG,
});

export const hidePromptDialog = () => ({
  type: types.HIDE_PROMPT_DIALOG,
});

export const hideAuditDialog = () => ({
  type: types.HIDE_AUDIT_DIALOG,
});

export const hideContractDialog = () => ({
  type: types.HIDE_CONTRACT_DIALOG,
});

// Reset
export const reset = () => ({
  type: types.RESET,
});
