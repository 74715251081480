// React
import React from 'react';

// Redux
import { useDispatch } from 'react-redux';

// Material
import { ContactMail as ContactUsIcon, Feedback as FeedbackIcon } from '@material-ui/icons';
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';

// Styles
import { useStyles } from './MenuOutreach-styles';

import { showFeedbackDialog } from '../../../../store/actions';

const MenuOutreach = (props) => {
  // Variables
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onClose, isAuth } = props;
  const contactLink = `mailto:${process.env.REACT_APP_KANSA_CONTACT_EMAIL}?subject=Hello!`;

  const feedbackHandler = () => {
    onClose();
    dispatch(showFeedbackDialog());
  };

  //JSX
  const header = <ListSubheader className={classes.header}>Outreach</ListSubheader>;

  let feedback = null;
  if (isAuth) {
    feedback = (
      <ListItem button component="a" onClick={feedbackHandler}>
        <ListItemIcon>
          <FeedbackIcon />
        </ListItemIcon>
        <ListItemText primary="Send Feedback" />
      </ListItem>
    );
  }

  const view = (
    <div className={classes.root}>
      <List className={classes.list} subheader={header}>
        <ListItem
          button
          component="a"
          href={contactLink}
          onClick={onClose}
          target="_blank"
          rel="noopener">
          <ListItemIcon>
            <ContactUsIcon />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </ListItem>
        {feedback}
      </List>
    </div>
  );

  return view;
};

export default MenuOutreach;
