export const stylesCreator = (theme) => ({
  Default: {
    root: {},
    snackbar: {
      [theme.breakpoints.up('md')]: {
        marginLeft: 120,
      },
    },
  },
});
