// Show
export const SHOW_FEEDBACK_DIALOG = 'dialog/showFeedbackDialog';
export const SHOW_PROMPT_DIALOG = 'dialog/showPromptDialog';
export const SHOW_AUDIT_DIALOG = 'dialog/showAuditDialog';
export const SHOW_CONTRACT_DIALOG = 'dialog/showContractDialog';

// Hide
export const HIDE_FEEDBACK_DIALOG = 'dialog/hideFeedbackDialog';
export const HIDE_PROMPT_DIALOG = 'dialog/hidePromptDialog';
export const HIDE_AUDIT_DIALOG = 'dialog/hideAuditDialog';
export const HIDE_CONTRACT_DIALOG = 'dialog/hideContractDialog';

// Reset
export const RESET = 'dialog/reset';
