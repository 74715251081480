// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Ethereum = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="-200 0 2100.000000 3400.000000">
      <g transform="translate(0.000000,3201.000000) scale(0.100000,-0.100000)">
        <path
          d="M8638 30372 c-526 -895 -1262 -2147 -1636 -2782 -373 -635 -914
-1555 -1202 -2045 -288 -489 -987 -1680 -1555 -2645 -568 -965 -1364 -2320
-1770 -3010 -406 -690 -1129 -1920 -1607 -2733 -478 -813 -868 -1480 -866
-1482 2 -1 368 -230 813 -507 446 -278 1343 -838 1995 -1245 652 -407 1442
-899 1755 -1095 314 -195 836 -521 1160 -723 325 -203 824 -515 1110 -693 286
-179 1022 -638 1635 -1020 613 -383 1122 -696 1130 -696 8 0 517 313 1130 696
613 382 1349 841 1635 1020 286 178 786 490 1110 693 325 202 847 528 1160
723 314 196 1103 688 1755 1095 652 407 1550 967 1995 1245 446 277 811 506
813 507 2 2 -388 669 -866 1482 -478 813 -1201 2043 -1607 2733 -406 690
-1202 2045 -1770 3010 -568 965 -1267 2156 -1555 2645 -288 490 -829 1410
-1202 2045 -374 635 -1110 1887 -1636 2782 -526 895 -959 1628 -962 1628 -3 0
-436 -733 -962 -1628z"
        />
        <path
          d="M33 13693 c19 -27 261 -372 537 -768 277 -396 700 -1001 940 -1345
240 -344 1105 -1581 1922 -2750 816 -1169 1777 -2543 2134 -3055 358 -511
1080 -1544 1604 -2295 525 -751 1284 -1837 1688 -2415 403 -577 737 -1050 742
-1050 5 0 339 473 742 1050 404 578 1163 1664 1688 2415 524 751 1375 1968
1890 2705 515 737 1507 2157 2205 3155 1408 2016 1724 2468 2523 3610 302 432
548 786 547 788 -1 1 -242 -148 -536 -331 -610 -381 -1857 -1159 -2824 -1762
-363 -227 -961 -600 -1330 -830 -368 -230 -877 -547 -1130 -705 -253 -158
-797 -498 -1210 -755 -412 -257 -903 -563 -1090 -680 -187 -117 -593 -370
-903 -563 -310 -194 -567 -352 -572 -352 -5 0 -262 158 -572 352 -310 193
-716 446 -903 563 -187 117 -677 423 -1090 680 -412 257 -957 597 -1210 755
-253 158 -761 475 -1130 705 -368 230 -967 603 -1330 830 -967 603 -2214 1381
-2824 1762 -293 183 -535 333 -538 333 -3 0 11 -21 30 -47z"
        />
      </g>
    </SvgIcon>
  );
};
