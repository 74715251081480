// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Numeral
import numeral from 'numeral';

// Material
import { Info as InfoIcon } from '@material-ui/icons';
import { Tooltip, Typography } from '@material-ui/core';

const StatItemRaw = (props) => {
  // Variables
  const {
    classes,
    value = 0,
    valueNumeralFormat = '0.0a',
    valueFontColor = 'primary',
    valueFontVariant = 'h5',
    text,
    textFontVariant = 'subtitle1',
    showTooltip = false,
    tooltipContent,
  } = props;

  let formattedValue = numeral(value).format(valueNumeralFormat);
  if (valueNumeralFormat === '0.0a') {
    formattedValue = formattedValue.replace('.0', '');
  }

  // JSX
  const view = (
    <div className={classes.root}>
      <Typography variant={valueFontVariant} color={valueFontColor}>
        {formattedValue}
      </Typography>
      <div className={classes.container}>
        <Typography style={{ marginRight: showTooltip ? 8 : 0 }} variant={textFontVariant}>
          {text}
        </Typography>
        {showTooltip ? (
          <Tooltip title={tooltipContent} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
            <InfoIcon fontSize="small" className={classes.tooltipIcon} />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );

  return view;
};

// Typechecking
StatItemRaw.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    tooltipIcon: PropTypes.string.isRequired,
  }),
  value: PropTypes.number,
  valueFontColor: PropTypes.string,
  valueFontVariant: PropTypes.string,
  valueNumeralFormat: PropTypes.string,
  text: PropTypes.string.isRequired,
  textFontVariant: PropTypes.string,
  showTooltip: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

// Dynamic styling
StatItemRaw.styledAs = 'StatItemRaw';

export default StatItemRaw;
