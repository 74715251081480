// Yup
import * as yup from 'yup';

export const auditDialogFormSchema = (source) =>
  yup.object().shape({
    blockchain: yup.string().required(),
    source: yup.string().required(),
    data: yup
      .string()
      .required()
      .matches(/0x[0-9A-Fa-f]+$/, 'data must be a valid hex string with a 0x prefix')
      .min(
        source === 'address' ? 42 : 3,
        source === 'address'
          ? 'address data must be 42 hex characters including a 0x prefix'
          : 'bytecode data must be at least 3 hex characters including a 0x prefix'
      )
      .max(
        source === 'address' ? 42 : 50000,
        source === 'address'
          ? 'address data must be 42 hex characters including a 0x prefix'
          : 'bytecode data must not exceed 50,000 hex characters including a 0x prefix'
      ),
  });
