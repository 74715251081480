// Axios
import axios from 'axios';

// QueryString
import qs from 'qs';

// Lodash
import _ from 'lodash';

// Redux
import { batch } from 'react-redux';

// Creators
import * as creators from './creators';
import { showAlert } from '../';

// Utils
import { getSearchQueryType } from '../../../utils/common';

// Constants
import { SEARCH_FILTER_ANY } from '../../../constants/search';

export const getResults = (query, filter, isPaged = false, isMoreResult = false) => {
  return (dispatch, getState) => {
    let queryParams = {
      query,
      filter: _.pickBy(filter, (value) => {
        return value !== SEARCH_FILTER_ANY.type;
      }),
    };

    if (isPaged) {
      const { page } = getState().search.data.pagination.next;
      queryParams.page = page;
    }

    const searchType = getSearchQueryType(queryParams.query);
    queryParams.type = searchType;
    dispatch(
      creators.getResultsStart({
        query,
        isPaged,
        type: searchType,
        isMoreLoading: isMoreResult,
      })
    );

    const searchUrl = `/search?${qs.stringify(queryParams)}`;

    axios
      .get(searchUrl)
      .then((response) => {
        dispatch(creators.getResultsSuccess(response.data));
      })
      .catch((error) => {
        batch(() => {
          dispatch(creators.getResultsFailure(error));
          dispatch(showAlert());
        });
      });
  };
};
