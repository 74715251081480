// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Moonbeam = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="0 0 310.000000 310.000000">
      <g transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)">
        <path
          d="M1950 2979 c-206 -17 -394 -111 -547 -272 -90 -96 -154 -202 -194
-322 -32 -95 -51 -238 -35 -261 10 -12 131 -14 871 -12 l860 3 -2 85 c-5 300
-247 615 -559 729 -130 47 -246 62 -394 50z"
        />
        <path
          d="M775 1935 c-47 -46 -17 -115 49 -115 65 0 91 79 40 119 -35 27 -59
26 -89 -4z"
        />
        <path
          d="M1002 1934 c-28 -19 -30 -74 -4 -97 17 -16 102 -17 1020 -17 989 0
1002 0 1022 20 11 11 20 31 20 45 0 14 -9 34 -20 45 -20 20 -33 20 -1018 20
-887 0 -1000 -2 -1020 -16z"
        />
        <path
          d="M372 1710 c-31 -13 -46 -56 -32 -91 19 -46 85 -56 117 -18 28 34 16
90 -23 107 -29 14 -31 14 -62 2z"
        />
        <path
          d="M577 1692 c-23 -25 -21 -68 3 -92 20 -20 33 -20 765 -20 l746 0 24
25 c30 29 32 54 5 83 l-21 22 -753 0 c-717 0 -754 -1 -769 -18z"
        />
        <path
          d="M750 1460 c-26 -26 -26 -81 -2 -103 29 -27 77 -22 102 10 52 66 -41
152 -100 93z"
        />
        <path
          d="M986 1449 c-31 -25 -34 -61 -6 -89 20 -20 33 -20 925 -20 892 0 905
0 925 20 28 28 25 64 -6 89 -27 21 -31 21 -919 21 -888 0 -892 0 -919 -21z"
        />
        <path
          d="M483 1230 c-69 -28 -48 -133 27 -133 59 0 91 67 53 111 -22 25 -51
33 -80 22z"
        />
        <path
          d="M682 1214 c-15 -10 -22 -25 -22 -49 0 -65 2 -65 401 -65 l358 0 20
26 c12 15 21 32 21 39 0 7 -9 24 -21 39 l-20 26 -358 0 c-306 0 -360 -2 -379
-16z"
        />
        <path
          d="M1612 1214 c-15 -10 -22 -25 -22 -49 0 -68 -38 -65 780 -65 818 0
780 -3 780 65 0 68 38 65 -780 65 -650 0 -738 -2 -758 -16z"
        />
        <path
          d="M1672 967 c-44 -46 -14 -117 48 -117 65 0 94 79 44 119 -35 27 -65
27 -92 -2z"
        />
        <path
          d="M1887 962 c-34 -37 -14 -95 36 -107 12 -3 225 -4 473 -3 428 3 453 4
473 22 26 24 27 67 3 89 -17 15 -66 17 -494 17 -447 0 -476 -1 -491 -18z"
        />
        <path
          d="M1120 723 c-25 -32 -20 -69 12 -95 44 -38 108 -9 110 50 2 69 -79
100 -122 45z"
        />
        <path
          d="M1350 737 c-32 -16 -40 -70 -14 -98 16 -18 37 -19 479 -19 435 0 463
1 478 18 25 28 21 67 -9 91 -26 21 -37 21 -468 21 -334 0 -447 -3 -466 -13z"
        />
      </g>
    </SvgIcon>
  );
};
