// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Polygon = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="-100 -100 2100.000000 2100.000000">
      <g transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)">
        <path
          d="M5085 18700 c-44 -15 -102 -37 -128 -49 -26 -11 -985 -569 -2130
-1240 -1145 -670 -2131 -1247 -2192 -1281 -149 -85 -230 -146 -319 -241 -158
-168 -256 -369 -299 -613 -18 -103 -18 -5179 0 -5282 45 -259 161 -489 341
-676 123 -128 198 -181 559 -391 1897 -1102 3941 -2286 3993 -2312 311 -156
706 -162 1067 -17 69 27 528 289 1537 876 791 461 1892 1100 2445 1420 553
321 1643 954 2421 1407 778 452 1444 838 1480 856 159 81 347 123 544 123 199
0 367 -33 545 -107 87 -35 2320 -1327 2441 -1411 87 -61 244 -221 304 -311 94
-141 159 -293 203 -474 16 -66 17 -183 20 -1497 4 -1555 5 -1530 -53 -1720
-36 -121 -123 -291 -199 -392 -76 -101 -206 -226 -308 -296 -90 -62 -2205
-1321 -2306 -1373 -321 -165 -741 -170 -1108 -13 -78 34 -2262 1297 -2405
1391 -140 92 -297 263 -384 418 -54 97 -98 209 -130 326 l-29 104 -3 928 c-1
510 -6 927 -10 927 -4 0 -448 -256 -987 -568 l-980 -567 0 -925 c0 -1022 -2
-991 65 -1192 80 -239 259 -480 467 -630 73 -53 4132 -2415 4328 -2519 50 -27
122 -60 159 -74 l69 -26 381 3 381 3 110 45 c82 34 656 362 2235 1279 1169
678 2156 1254 2194 1279 259 174 453 475 517 804 18 96 19 180 19 2708 0 2523
-1 2613 -19 2707 -34 173 -89 311 -186 460 -69 106 -248 287 -355 359 -93 63
-4242 2471 -4348 2524 -231 114 -528 148 -809 91 -113 -23 -249 -69 -334 -111
-30 -16 -708 -413 -1505 -883 -1223 -720 -1600 -937 -2414 -1387 -916 -506
-1039 -577 -2420 -1405 -800 -480 -1486 -888 -1525 -908 -281 -140 -656 -156
-991 -42 -60 21 -130 48 -155 61 -87 44 -2334 1406 -2400 1455 -246 182 -429
485 -484 803 -23 133 -23 2888 0 3022 55 321 238 616 505 816 72 54 2272 1339
2415 1411 306 153 726 156 1080 8 58 -25 490 -280 1234 -730 629 -380 1171
-710 1205 -733 190 -129 379 -388 460 -630 62 -186 59 -137 65 -1169 l6 -945
980 567 980 567 3 954 c2 1038 3 1024 -54 1203 -38 118 -116 269 -188 365 -74
98 -203 225 -296 292 -72 51 -4197 2476 -4300 2528 -163 82 -147 80 -558 79
l-367 -1 -80 -28z"
        />
      </g>
    </SvgIcon>
  );
};
