// React
import React, { useEffect, useState } from 'react';

// Lodash
import _ from 'lodash';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Router
import { useHistory } from 'react-router-dom';

// Hook Form
import { Controller, useForm } from 'react-hook-form';

// Schema
import { auditDialogFormSchema } from './AuditDialogForm-schema';

// Material
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

// Store
import { createAudit, resetAuditsError } from '../../../store/actions';

// Styles
import { useStyles } from './AuditDialogForm-styles';

// Constants
import { SEARCH_FILTER } from '../../../constants/search';

const AuditDialogForm = (props) => {
  // Variables
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const initData = useSelector((state) => state.dialog.audit.initData);
  const error = useSelector((state) => state.audits.error);
  const [source, setSource] = useState('');

  const { formRef, onClose } = props;
  const { handleSubmit, errors, control, watch } = useForm({
    validationSchema: auditDialogFormSchema(source),
    defaultValues: initData,
  });

  const blockchains = _.filter(
    SEARCH_FILTER.blockchains,
    (blockchain) => blockchain.type !== 'all'
  );
  const watchBlockchain = watch('blockchain');

  const sources = [
    { type: 'address', label: 'Address' },
    { type: 'bytecode', label: 'Bytecode' },
  ];
  const watchSource = watch('source');

  // Handlers
  const auditHandler = (data) => {
    dispatch(createAudit(data));
    onClose();
  };

  // Hooks
  useEffect(() => {
    setSource(watchSource);
  }, [watchSource, watchBlockchain]);

  //JSX
  if (error && error.message.includes('403')) {
    dispatch(resetAuditsError());
    history.push('/pricing');
  }

  const selectBlockchain = (
    <Select>
      {blockchains.map((source, index) => {
        return (
          <MenuItem key={index} value={source.type}>
            {source.label}
          </MenuItem>
        );
      })}
    </Select>
  );

  const selectSource = (
    <Select>
      {sources.map((source, index) => {
        return (
          <MenuItem key={index} value={source.type}>
            {source.label}
          </MenuItem>
        );
      })}
    </Select>
  );

  let placeholder = 'Copy/paste an address or a bytecode string';
  if (watchSource) {
    if (watchSource === 'address') {
      placeholder = 'Address string of a deployed contract';
    } else {
      placeholder = 'Bytecode string of a contract';
    }
  }

  const view = (
    <form
      className={classes.root}
      autoComplete="off"
      ref={formRef}
      onSubmit={handleSubmit(auditHandler)}
      noValidate>
      <FormControl className={classes.source} error={!!errors.blockchain}>
        <InputLabel>Blockchain</InputLabel>
        <Controller as={selectBlockchain} control={control} name="blockchain" />
        <FormHelperText>{errors.blockchain && errors.blockchain.message}</FormHelperText>
      </FormControl>
      <FormControl className={classes.source} error={!!errors.source}>
        <InputLabel>Source</InputLabel>
        <Controller as={selectSource} control={control} name="source" />
        <FormHelperText>{errors.source && errors.source.message}</FormHelperText>
      </FormControl>
      <Controller
        className={classes.data}
        as={
          <TextField
            error={!!errors.data}
            helperText={errors.data && errors.data.message}
            InputProps={{
              className: classes.dataCode,
            }}
          />
        }
        control={control}
        label="Data"
        name="data"
        placeholder={placeholder}
        variant="outlined"
        maxRows={5}
        minRows={5}
        multiline
      />
    </form>
  );

  return view;
};

export default AuditDialogForm;
