// React
import React, { useEffect } from 'react';

// Lodash
import _ from 'lodash';

// Hook Form
import { Controller, useForm } from 'react-hook-form';

// Schema
import { filterFormSchema } from './FilterForm-schema';

// Material
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Store
import { setSearchFilter } from '../../../../store/actions';

// Constants
import { SEARCH_FILTER } from '../../../../constants/search';

// Styles
import { useStyles } from './FilterForm-styles';

const FilterForm = () => {
  // Variables
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.search.data.filter);
  const { control, errors, watch } = useForm({
    validationSchema: filterFormSchema,
  });
  const watchAllFields = watch();

  // Hooks
  useEffect(() => {
    if (_.isEmpty(watchAllFields) || _.isEqual(watchAllFields, filter)) {
      return;
    }
    dispatch(setSearchFilter(watchAllFields));
  }, [dispatch, filter, watchAllFields]);

  //JSX
  const getSelection = (props, data) => {
    return (
      <Select value={props.value || ''} onChange={(event) => props.onChange(event.target.value)}>
        {data.map((item, index) => {
          return (
            <MenuItem key={index} value={item.type}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const view = (
    <form className={classes.root}>
      <FormControl className={classes.select} error={!!errors.blockchain}>
        <InputLabel>Blockchain</InputLabel>
        <Controller
          as={(props) => getSelection(props, SEARCH_FILTER.blockchains)}
          name="blockchain"
          control={control}
          defaultValue="all"
        />
        <FormHelperText>{errors.securityScore && errors.securityScore.message}</FormHelperText>
      </FormControl>
      <FormControl className={classes.select} error={!!errors.tokenStandard}>
        <InputLabel>Token Standard</InputLabel>
        <Controller
          as={(props) => getSelection(props, SEARCH_FILTER.tokenStandards)}
          name="tokenStandard"
          control={control}
          defaultValue="all"
        />
        <FormHelperText>{errors.tokenStandard && errors.tokenStandard.message}</FormHelperText>
      </FormControl>
      <FormControl className={classes.select} error={!!errors.securityScore}>
        <InputLabel>Security Score</InputLabel>
        <Controller
          as={(props) => getSelection(props, SEARCH_FILTER.securityScores)}
          name="securityScore"
          control={control}
          defaultValue="all"
        />
        <FormHelperText>{errors.securityScore && errors.securityScore.message}</FormHelperText>
      </FormControl>
    </form>
  );

  return view;
};

export default FilterForm;
