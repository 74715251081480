// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Blockchain = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="0 0 512.000000 512.000000">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          d="M2450 4784 c-112 -30 -222 -120 -271 -222 -13 -26 -30 -82 -39 -126
l-15 -78 -660 -369 c-383 -215 -672 -382 -687 -399 l-28 -30 0 -775 c0 -613
-3 -775 -12 -775 -32 0 -123 -54 -173 -101 -92 -87 -135 -185 -135 -309 0
-124 43 -222 135 -309 153 -145 380 -156 548 -27 l48 36 677 -382 c611 -344
681 -381 722 -382 41 0 108 36 722 382 l678 382 47 -36 c202 -155 484 -102
622 114 79 126 81 307 5 438 -47 81 -194 193 -251 194 -10 0 -13 162 -13 775
l0 775 -27 30 c-17 18 -298 181 -688 399 l-660 369 -15 78 c-21 105 -52 167
-120 235 -111 112 -260 153 -410 113z m213 -226 c116 -64 142 -224 54 -325
-101 -115 -277 -89 -349 51 -29 56 -22 149 15 205 63 96 180 124 280 69z
m-293 -565 c25 -11 53 -23 63 -27 16 -5 17 -26 15 -303 l-3 -297 -295 -172
c-166 -97 -304 -184 -315 -199 -19 -25 -20 -44 -23 -359 l-3 -332 -334 -192
-335 -192 -52 34 c-29 19 -70 40 -91 47 l-37 13 0 721 0 721 367 205 c201 112
479 267 616 344 l251 140 65 -66 c36 -36 86 -75 111 -86z m1188 -195 l602
-342 0 -721 0 -721 -37 -13 c-21 -7 -61 -28 -90 -47 l-52 -33 -335 191 -335
192 -3 332 c-3 316 -4 334 -23 360 -12 15 -146 100 -315 199 l-295 172 -3 297
c-2 276 -1 297 15 302 66 22 131 65 181 120 36 39 63 61 72 58 8 -3 286 -159
618 -346z m-733 -761 l260 -152 0 -319 0 -320 -262 -152 -262 -153 -263 153
-263 152 0 319 0 319 260 152 c143 84 262 152 265 152 3 0 122 -68 265 -151z
m-622 -1142 c271 -158 323 -185 358 -185 34 0 86 27 351 181 171 100 319 184
328 186 11 3 143 -67 319 -169 290 -166 302 -174 294 -198 -4 -14 -7 -69 -8
-124 l0 -99 -642 -359 -643 -360 -642 360 -643 360 0 99 c-1 54 -4 109 -8 123
-8 24 3 31 290 197 164 94 305 172 313 172 9 1 158 -82 333 -184z m-1231 -121
c59 -41 92 -104 92 -174 0 -181 -214 -277 -351 -157 -115 101 -89 274 52 351
21 12 49 15 97 13 56 -2 74 -8 110 -33z m3386 17 c138 -71 163 -248 49 -348
-102 -90 -260 -62 -327 57 -60 109 -7 252 110 299 43 17 128 13 168 -8z"
        />
      </g>
    </SvgIcon>
  );
};
