// Components
import LogoRaw, { logoStyler } from '../../LogoRaw';

// Utils
import { makeStyles, withStyles } from '../../../utils';

export const stylesCreator = (theme) => ({
  Default: {
    root: {},
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: 240,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: 240,
    },
    drawerNote: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      height: '100%',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  },
  LogoRaw: {
    ...logoStyler(theme).Default,
    logo: {
      ...logoStyler(theme).Default.logo,
      fontSize: 26,
      textAlign: 'center',
    },
  },
});

// Local
export const useStyles = makeStyles(stylesCreator);

export const Logo = withStyles(stylesCreator, LogoRaw);
