// React
import React from 'react';

// Material
import { Typography } from '@material-ui/core';

// Styles
import { useStyles } from './Footer-styles';

const Footer = () => {
  // Variables
  const classes = useStyles();

  //JSX
  const view = (
    <div className={classes.root}>
      <Typography variant="caption">
        &copy; {new Date().getFullYear()} Blockstead &mdash; All rights reserved
      </Typography>
    </div>
  );

  return view;
};

export default Footer;
