// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Avalanche = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="0 0 340.000000 340.000000">
      <g transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)">
        <path
          d="M1723 3129 c-22 -14 -55 -59 -102 -142 -296 -521 -1178 -2091 -1193
-2126 -23 -50 -18 -99 14 -143 41 -58 41 -58 474 -58 381 0 398 1 429 20 26
16 88 114 291 458 141 240 353 600 471 799 196 333 213 365 213 409 0 42 -16
75 -162 333 -223 396 -240 423 -278 449 -43 29 -113 30 -157 1z"
        />
        <path
          d="M2545 1681 c-16 -10 -37 -27 -46 -37 -9 -10 -112 -190 -230 -399
l-213 -380 -1 -63 c0 -62 1 -64 42 -103 l42 -39 472 0 c461 0 472 0 506 21 75
47 86 130 31 230 -152 273 -413 728 -426 743 -41 46 -126 59 -177 27z"
        />
      </g>
    </SvgIcon>
  );
};
