// Types
import * as types from '../actions/reports/types';

// Utils
import { updateObject } from '../../utils';

// State
const initialState = {
  error: null,
  isBusy: false,
  isDownloading: false,
  noResults: false,
  isInit: false,
  summary: null,
  scans: [],
  codes: null,
  vulnerabilities: [],
  manualAudits: [],
  contract: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Get
    case types.GET_REPORT_START: {
      return updateObject(state, {
        error: null,
        isBusy: true,
      });
    }
    case types.GET_REPORT_SUCCESS: {
      return updateObject(state, {
        _id: action.payload.data._id,
        contract: action.payload.data.contract,
        summary: action.payload.data.summary,
        scans: action.payload.data.scans,
        codes: action.payload.data.codes,
        vulnerabilities: action.payload.data.vulnerabilities,
        manualAudits: action.payload.data.manualAudits,
        isBusy: false,
        isInit: true,
      });
    }
    case types.GET_REPORT_FAILURE: {
      return updateObject(state, {
        error: action.payload,
        isBusy: false,
        noResults: true,
        isInit: true,
      });
    }

    case types.GET_REPORT_URL_START: {
      return updateObject(state, {
        error: null,
        isDownloading: true,
      });
    }
    case types.GET_REPORT_URL_SUCCESS: {
      return updateObject(state, {
        isDownloading: false,
      });
    }
    case types.GET_REPORT_URL_FAILURE: {
      return updateObject(state, {
        error: action.payload,
        isDownloading: false,
      });
    }

    // Reset
    case types.RESET:
      return initialState;

    // Default
    default: {
      return state;
    }
  }
};

export default reducer;
