export const GET_REPORT_START = 'reports/getReportStart';
export const GET_REPORT_SUCCESS = 'reports/getReportSuccess';
export const GET_REPORT_FAILURE = 'reports/getReportFailure';

export const GET_REPORT_URL_START = 'reports/getReportUrlStart';
export const GET_REPORT_URL_SUCCESS = 'reports/getReportUrlSuccess';
export const GET_REPORT_URL_FAILURE = 'reports/getReportUrlFailure';

export const RESET = 'reports/reset';
export const RESET_ERROR = 'reports/resetError';
