// Types
import * as types from '../actions/computedData/types';

// Shared
import { updateObject } from '../../utils';

// State
const initialState = {
  auditStats: {
    reports: 0,
    blockchains: 0,
    vulnerableContracts: 0,
    totalIncidents: 0,
  },
  reportLists: {
    topRiskyReports: [],
    recentReports: [],
  },
  error: null,
  isBusy: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COMPUTED_DATA_START:
      return updateObject(state, {
        isBusy: true,
      });
    case types.GET_COMPUTED_DATA_SUCCESS:
      return updateObject(state, {
        reportLists: action.payload.reportLists.data,
        auditStats: action.payload.auditStats.data,
        isBusy: false,
      });
    case types.GET_COMPUTED_DATA_FAILURE:
      return updateObject(state, {
        error: action.payload,
        isBusy: false,
      });
    // Reset
    case types.RESET: {
      return initialState;
    }
    // Default
    default:
      return state;
  }
};

export default reducer;
