import React from 'react';

import { useSelector } from 'react-redux';

import { Grid, List, ListSubheader, Paper, Typography } from '@material-ui/core';

import BlockchainIcon from '../BlockchainIcon/BlockchainIcon';
import TopListItem from './TopListItem';

import { LazyProgress, SearchBox, StatItem, useStyles } from './Main-styles';

import { SEARCH_FILTER } from '../../constants/search';

export const Main = () => {
  // Variables
  const classes = useStyles();
  const reportLists = useSelector((state) => state.computedData.reportLists);
  const auditStats = useSelector((state) => state.computedData.auditStats);
  const isBusy = useSelector((state) => state.computedData.isBusy);

  if (isBusy) {
    return <LazyProgress />;
  }

  const blockchainsGrid = (
    <Grid container spacing={2} style={{ padding: 8 }}>
      {SEARCH_FILTER.blockchains
        .map((chain) => {
          return (
            <Grid item key={chain.type} xs={6}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BlockchainIcon symbol={chain.type} />
                <Typography
                  style={{
                    marginLeft: 8,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  variant="body2">
                  {chain.label}
                </Typography>
              </div>
            </Grid>
          );
        })
        .slice(1)}
    </Grid>
  );

  // JSX
  const view = (
    <div className={classes.root}>
      <Typography variant="h5" color="primary">
        Security audits for smart contracts
      </Typography>
      <Typography className={classes.subtitle} gutterBottom variant="subtitle1">
        Get a complete report for any contract before you invest, insure, or trade.
      </Typography>
      <SearchBox enableFilter={false} />
      <Paper className={classes.statsContainer} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3}>
            <StatItem
              value={auditStats.blockchains}
              text="Blockchains"
              showTooltip
              tooltipContent={blockchainsGrid}
              numeralFormat="0a"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <StatItem value={auditStats.reports} text="Audited Contracts" />
          </Grid>
          <Grid item xs={6} sm={3}>
            <StatItem
              value={auditStats.vulnerableContracts}
              text="Risky Contracts"
              tooltipContent="These contracts have a security score less than 75%"
              showTooltip
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <StatItem value={auditStats.totalIncidents} text="Total Incidents" />
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: 8 }}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} variant="outlined">
            <List>
              <ListSubheader className={classes.listTitle} color="inherit" disableSticky>
                Recently Audited
              </ListSubheader>
              {reportLists.recentReports.map((report) => (
                <TopListItem key={report._id} data={report} />
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} variant="outlined">
            <List>
              <ListSubheader className={classes.listTitle} color="inherit" disableSticky>
                Top Risky
              </ListSubheader>
              {reportLists.topRiskyReports.map((report) => (
                <TopListItem key={report._id} data={report} />
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );

  return view;
};

export default Main;
