// Axios
import axios from 'axios';

// Redux
import { batch } from 'react-redux';

// Lodash
import _ from 'lodash';

// QS
import qs from 'qs';

// Creators
import * as creators from './creators';

// Actions
import { showAlert } from '../';

// Get
export const getAudits = (page = 1, limit = 10) => {
  return (dispatch) => {
    dispatch(creators.getAuditsStart());

    let queryParams = {
      page,
      limit,
    };

    const url = `/audits?${qs.stringify(queryParams)}`;

    axios
      .get(url)
      .then((response) => {
        dispatch(creators.getAuditsSuccess(response.data));
      })
      .catch((error) => {
        batch(() => {
          dispatch(creators.getAuditsFailure(error));
          dispatch(showAlert(_.get(error, 'response.data.error')));
        });
      });
  };
};

// Create
export const createAudit = (data) => {
  return (dispatch) => {
    dispatch(creators.createAuditStart());
    axios
      .post('/audits', data)
      .then((response) => {
        batch(() => {
          dispatch(creators.createAuditSuccess(response.data.data));
          dispatch(showAlert('Audit request submitted.', 'success'));
        });
        dispatch(creators.resetCreateSuccess());
      })
      .catch((error) => {
        batch(() => {
          dispatch(creators.createAuditFailure(error));
          dispatch(showAlert(_.get(error, 'response.data.error')));
        });
      });
  };
};
