// Utils
import { makeStyles } from '../../../utils';

export const stylesCreator = (theme) => ({
  Default: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: theme.spacing(1),
      '&:nth-child(2)': {
        marginTop: 0,
      },
      '&:last-child': {
        border: 'none',
      },
    },
    gridContainer: {
      paddingTop: theme.spacing(1.5),
    },
    gridItemTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    gridItemText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    link: {
      marginLeft: theme.spacing(1),
      width: 'fit-content',
      '&:hover': {
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
    title: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
});

// Local
export const useStyles = makeStyles(stylesCreator);
