import * as types from './types';

// Get
export const getAuditsStart = () => ({
  type: types.GET_AUDITS_START,
});

export const getAuditsSuccess = (data) => ({
  type: types.GET_AUDITS_SUCCESS,
  payload: data,
});

export const getAuditsFailure = (error) => ({
  type: types.GET_AUDITS_FAILURE,
  payload: error,
});

// Create
export const createAuditStart = () => ({
  type: types.CREATE_AUDIT_START,
});

export const createAuditSuccess = (data) => ({
  type: types.CREATE_AUDIT_SUCCESS,
  payload: data,
});

export const createAuditFailure = (error) => ({
  type: types.CREATE_AUDIT_FAILURE,
  payload: error,
});

// Reset
export const reset = () => ({
  type: types.RESET,
});

export const resetCreateSuccess = () => ({
  type: types.RESET_CREATE_SUCCESS,
});

export const resetError = () => ({
  type: types.RESET_ERROR,
});
