// React
import React from 'react';

// Router
import { NavLink } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Material
import {
  BugReport as BugReportIcon,
  Home as MainIcon,
  MonetizationOn as PricingIcon,
  Payment as SubscriptionIcon,
} from '@material-ui/icons';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

// Styles
import { useStyles } from './MenuGeneral-styles';

const MenuGeneral = (props) => {
  // Variables
  const classes = useStyles();
  const { onClose } = props;
  const isAuth = useSelector((state) => state.auth.data.token !== null);

  let pricing = null;
  if (!isAuth) {
    pricing = (
      <ListItem button component={NavLink} to="/pricing" rel="noopener" onClick={onClose}>
        <ListItemIcon>
          <PricingIcon />
        </ListItemIcon>
        <ListItemText primary="Pricing" />
      </ListItem>
    );
  }

  let subscription = null;
  if (isAuth) {
    subscription = (
      <ListItem button component={NavLink} to="/subscription" onClick={onClose}>
        <ListItemIcon>
          <SubscriptionIcon />
        </ListItemIcon>
        <ListItemText primary="Subscription" />
      </ListItem>
    );
  }

  let scans = null;
  if (isAuth) {
    scans = (
      <ListItem button component={NavLink} to="/audits" onClick={onClose}>
        <ListItemIcon>
          <BugReportIcon />
        </ListItemIcon>
        <ListItemText primary="Audits" />
      </ListItem>
    );
  }

  const view = (
    <div className={classes.root}>
      <List className={classes.list}>
        <ListItem button component={NavLink} to="/" rel="noopener" onClick={onClose}>
          <ListItemIcon>
            <MainIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {pricing}
        {subscription}
        {scans}
      </List>
    </div>
  );

  return view;
};

export default MenuGeneral;
