import numeral from 'numeral';

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const bitcoinAddressRegexStr =
  '^((1[a-km-zA-HJ-NP-Z1-9]{25,34})|(3[a-km-zA-HJ-NP-Z1-9]{25,34})|(bc1[a-zA-HJ-NP-Z0-9]{25,39}))$';

export const validateEthereumAddress = (value) => {
  return new RegExp(/^0x[a-fA-F0-9]{40}$/).test(value);
};

export const validateEthereumTx = (value) => {
  return new RegExp(/^0x([A-Fa-f0-9]{64})$/).test(value);
};

export const validateEthereumBytecode = (value) => {
  return value && value.length <= 50000 && new RegExp(/0x[0-9A-Fa-f]+$/).test(value);
};

export const titleShortener = (type, value) => {
  if (type === 'hash') {
    return 'Tx [' + value?.substring(0, 8) + ']';
  } else if (['fromAddress', 'toAddress', 'from', 'to'].includes(type)) {
    return 'Address [' + value?.substring(0, 8) + ']';
  } else if (type === 'reportId') {
    return 'Report [' + value?.substring(value.length - 8) + ']';
  } else {
    return value;
  }
};

export const formatDateString = (dateString, dateOptions = {}, replaceCommas = true) => {
  return !isNaN(Date.parse(dateString))
    ? new Date(dateString)
        .toLocaleDateString('en-CA', dateOptions)
        .replace(',', replaceCommas ? '' : ',')
    : 'N/A';
};

export const toProperCase = (text) => {
  return text.replace(/\w\S*/g, function (matchedText) {
    return matchedText.charAt(0).toUpperCase() + matchedText.substr(1).toLowerCase();
  });
};

export const getSearchQueryType = (query) => {
  if (validateEthereumAddress(query)) return 'address';
  else if (validateEthereumBytecode(query)) return 'bytecodeHash';
  else return 'label';
};

export const getSecurityGrade = (score) => {
  if (score > 0.75) {
    return 'high';
  } else if (score > 0.25) {
    return 'medium';
  } else {
    return 'low';
  }
};

export const annotateSecurityScore = (score) => {
  if (typeof score !== 'number') {
    return score;
  } else {
    const securityScore = numeral(score).format('0%');
    const grade = getSecurityGrade(score);
    return `${securityScore} (${toProperCase(grade)})`;
  }
};

export const getTokenStandard = (data) => {
  let tokenStandard = 'ERC-';

  // Binance special case
  if (data.blockchain === 'bsc') {
    tokenStandard = 'BEP-';
  }

  if (data.isErc20) {
    tokenStandard += '20';
  } else if (data.isErc721) {
    tokenStandard += '721 (NFT)';
  } else {
    tokenStandard = 'Other';
  }

  return tokenStandard;
};

export const addressShortener = (address) => {
  return address.replace(/(0x[\dA-Fa-f]{6})[\dA-Fa-f]+([\dA-Fa-f]{6})/, '$1...$2');
};

export const getAuditDuration = (durationMs) => {
  if (durationMs < 0 || !durationMs) return 'N/A';
  var auditDurationMin = Math.ceil(durationMs / (60 * 1000)); //
  auditDurationMin = `${auditDurationMin} ${auditDurationMin > 1 ? 'minutes' : 'minute'}`;
  return auditDurationMin;
};

export const formatDetectedProxyPattern = (pattern) => {
  switch (pattern) {
    case 'eip-1167':
      return 'EIP-1167';
    case 'eip-1967-direct':
      return 'EIP-1967';
    case 'eip-1967-beacon':
      return 'EIP-1967';
    case 'openzeppelin':
      return 'OpenZeppelin';
    case 'eip-1822':
      return 'EIP-1822';
    case 'eip-897':
      return 'EIP-897';
    case 'gnosis-safe':
      return 'Gnosis Safe';
    case 'compound-comptroller':
      return 'Compound';
    default:
      console.error(`Unknown detected proxy pattern ${pattern}`);
      return 'N/A';
  }
};

export const getBlockchainExplorerLink = (blockchain, address) => {
  let link = '';
  switch (blockchain) {
    case 'eth':
      link = `https://etherscan.io/address/${address}`;
      break;
    case 'bsc':
      link = `https://bscscan.com/address/${address}`;
      break;
    case 'matic':
      link = `https://polygonscan.com/address/${address}`;
      break;
    case 'avax':
      link = `https://snowtrace.io/address/${address}`;
      break;
    case 'ftm':
      link = `https://ftmscan.com/address/${address}`;
      break;
    case 'arb':
      link = `https://arbiscan.io/address/${address}`;
      break;
    case 'op':
      link = `https://optimistic.etherscan.io/address/${address}`;
      break;
    case 'glmr':
      link = `https://moonscan.io/address/${address}`;
      break;
    case 'cro':
      link = `https://cronoscan.com/address/${address}`;
      break;
    case 'gnosis':
      link = `https://gnosisscan.io/address/${address}`;
      break;
    case 'movr':
      link = `https://moonriver.moonscan.io/address/${address}`;
      break;
    case 'celo':
      link = `https://celoscan.io/address/${address}`;
      break;
  }
  return link;
};
