// React
import React from 'react';

// Material
import { Divider, Drawer, Hidden } from '@material-ui/core';

// Components
import General from './General';
import Outreach from './Outreach';
import References from './References';
import Theme from './Theme';

// Styles
import { Logo, useStyles } from './HeaderMenu-styles';

const HeaderMenu = (props) => {
  // Variables
  const classes = useStyles();
  const { open, onClose, isAuth } = props;

  const container = window !== undefined ? () => window.document.body : undefined;

  const drawer = (
    <>
      <Logo onClick={onClose} />
      <General onClose={onClose} />
      <Divider />
      <Theme onClose={onClose} isAuth={isAuth} />
      <Divider />
      <References onClose={onClose} />
      <Divider />
      <Outreach onClose={onClose} isAuth={isAuth} />
    </>
  );

  //JSX
  const view = (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="temporary"
          anchor="left"
          open={open}
          onClose={onClose}
          ModalProps={{ keepMounted: true }}>
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open>
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );

  return view;
};

export default HeaderMenu;
