// Utils
import { makeStyles } from '../../../utils';

export const stylesCreator = (theme) => ({
  Default: {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    source: {
      marginBottom: theme.spacing(1),
    },
    data: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    dataCode: {
      fontSize: 12,
      fontFamily: 'monospace',
    },
  },
});

// Local
export const useStyles = makeStyles(stylesCreator);
