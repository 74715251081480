// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Optimism = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="-100 -100 2100.000000 2100.000000">
      <g transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)">
        <path
          d="M9665 19994 c-761 -38 -1323 -113 -1950 -261 -2687 -633 -4988 -2340
-6368 -4725 -713 -1232 -1159 -2617 -1296 -4028 -37 -374 -45 -561 -45 -980 0
-530 25 -908 95 -1397 273 -1933 1098 -3729 2392 -5207 239 -273 631 -665 898
-899 1567 -1371 3462 -2206 5529 -2437 981 -109 2054 -61 3036 136 2418 485
4550 1826 6033 3794 1672 2219 2344 5048 1850 7785 -653 3617 -3241 6588
-6734 7728 -797 260 -1568 410 -2425 472 -201 15 -858 27 -1015 19z m-1265
-7269 c510 -70 916 -256 1201 -551 285 -297 420 -645 420 -1084 1 -189 -16
-323 -81 -679 -201 -1094 -363 -1598 -655 -2041 -380 -575 -918 -894 -1679
-995 -212 -28 -509 -39 -706 -27 -234 15 -371 35 -555 82 -362 92 -652 247
-871 465 -233 233 -353 482 -405 840 -16 110 -13 384 6 530 36 279 235 1220
331 1563 158 563 432 1024 794 1335 395 340 889 528 1510 577 160 12 552 4
690 -15z m5563 -83 c457 -74 791 -225 1033 -467 174 -174 263 -345 315 -606
20 -97 16 -402 -5 -524 -76 -431 -239 -813 -466 -1090 -267 -326 -670 -551
-1156 -645 -264 -51 -311 -53 -1034 -59 l-685 -6 -172 -820 c-94 -451 -177
-837 -183 -858 -6 -21 -29 -56 -51 -78 -76 -76 -53 -74 -624 -77 -280 -2 -539
0 -574 3 -53 6 -69 12 -99 39 -68 62 -100 -112 477 2606 286 1350 528 2470
538 2488 20 39 74 89 117 108 26 11 235 13 1231 11 1188 -3 1201 -3 1338 -25z"
        />
        <path
          d="M7742 11660 c-293 -41 -558 -200 -722 -431 -65 -92 -138 -236 -180
-354 -99 -280 -336 -1360 -360 -1646 -41 -461 141 -731 530 -790 125 -19 366
-7 484 25 363 95 623 352 776 766 65 177 255 1020 326 1448 32 190 36 402 10
522 -51 236 -207 391 -446 445 -87 20 -323 28 -418 15z"
        />
        <path
          d="M12315 10973 c-76 -362 -140 -664 -143 -670 -5 -17 943 -19 1034 -2
335 59 587 290 676 619 19 71 23 111 23 240 0 153 0 156 -31 221 -42 88 -93
142 -168 179 -122 60 -149 62 -726 67 l-526 5 -139 -659z"
        />
      </g>
    </SvgIcon>
  );
};
