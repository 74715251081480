// Get
export const GET_AUDITS_START = 'audits/getAuditsStart';
export const GET_AUDITS_SUCCESS = 'audits/getAuditsSuccess';
export const GET_AUDITS_FAILURE = 'audits/getAuditsFailure';

// Create
export const CREATE_AUDIT_START = 'audits/createAuditStart';
export const CREATE_AUDIT_SUCCESS = 'audits/createAuditSuccess';
export const CREATE_AUDIT_FAILURE = 'audits/createAuditFailure';

// Rest
export const RESET = 'audits/reset';
export const RESET_CREATE_SUCCESS = 'audits/resetCreateSuccess';
export const RESET_ERROR = 'reports/resetError';
