// React
import React, { Fragment, useEffect, useState } from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Router
import { useHistory, useLocation } from 'react-router-dom';

// QueryString
import qs from 'qs';

// Material
import { Divider, IconButton, InputBase, Paper } from '@material-ui/core';
import { FilterList as FilterListIcon, Search as SearchIcon } from '@material-ui/icons';

// Components
import FilterScrollList from './FilterScrollList';

// Store
import { resetFilter } from '../../store/actions';

const SearchBoxRaw = (props) => {
  // Variables
  const {
    classes,
    placeholder = 'Enter contract address or token name',
    enableFilter = true,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);

  const lastQuery = useSelector((state) => state.search.data.query);

  // Hooks
  useEffect(() => {
    if (lastQuery && location.pathname !== '/') setQuery(lastQuery);
    else setQuery('');
  }, [lastQuery, location.pathname]);

  // Handlers
  const filterShowHandler = () => {
    setShowFilter(!showFilter);

    // Reset filter when hidden
    if (showFilter) {
      dispatch(resetFilter());
    }
  };

  const queryChangeHandler = (event) => {
    const query = event.target.value;
    setQuery(query);
  };

  const querySubmitHandler = (event) => {
    event.preventDefault();
    const trimmedQuery = query.trim();
    if (trimmedQuery !== '') {
      const location = {
        pathname: '/search',
        search: qs.stringify({ query: trimmedQuery }),
      };
      setQuery(trimmedQuery);
      history.push(location);
    }
  };

  let filterScrollList = null;
  if (enableFilter && showFilter) {
    filterScrollList = <FilterScrollList />;
  }

  let filterDivier = null;
  if (enableFilter) {
    filterDivier = (
      <Fragment>
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton color={showFilter ? 'primary' : 'default'} onClick={filterShowHandler}>
          <FilterListIcon />
        </IconButton>
      </Fragment>
    );
  }

  const view = (
    <div className={classes.root}>
      <Paper
        component="form"
        className={classes.paper}
        variant="outlined"
        onSubmit={querySubmitHandler}>
        <IconButton type="submit" aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          type="text"
          value={query ?? ''}
          placeholder={placeholder}
          onChange={queryChangeHandler}
          inputProps={{
            autoCorrect: 'off',
            autoCapitalize: 'off',
            spellCheck: 'false',
          }}
        />
        {filterDivier}
      </Paper>
      {filterScrollList}
    </div>
  );

  return view;
};

// Typechecking
SearchBoxRaw.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.string,
};

// Dynamic styling
SearchBoxRaw.styledAs = 'SearchBoxRaw';

export default SearchBoxRaw;
