// Types
import * as types from '../actions/dialog/types';

// Shared
import { updateObject } from '../../utils';

// State
const initialState = {
  feedback: {
    open: false,
  },
  prompt: {
    open: false,
  },
  audit: {
    open: false,
    initData: { source: '', data: '' },
  },
  contract: {
    open: false,
    initData: { name: '', source: '', data: '' },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Show
    case types.SHOW_FEEDBACK_DIALOG: {
      return updateObject(state, {
        feedback: updateObject(state.feedback, {
          open: true,
        }),
      });
    }
    case types.SHOW_PROMPT_DIALOG: {
      return updateObject(state, {
        prompt: updateObject(state.prompt, {
          open: true,
        }),
      });
    }
    case types.SHOW_AUDIT_DIALOG: {
      return updateObject(state, {
        audit: updateObject(state.audit, {
          open: true,
          initData: action.payload,
        }),
      });
    }
    case types.SHOW_CONTRACT_DIALOG: {
      return updateObject(state, {
        contract: updateObject(state.contract, {
          open: true,
          initData: action.payload,
        }),
      });
    }
    // Hide
    case types.HIDE_FEEDBACK_DIALOG: {
      return updateObject(state, {
        feedback: updateObject(state.feedback, {
          open: false,
        }),
      });
    }
    case types.HIDE_PROMPT_DIALOG: {
      return updateObject(state, {
        prompt: updateObject(state.prompt, {
          open: false,
        }),
      });
    }
    case types.HIDE_AUDIT_DIALOG: {
      return updateObject(state, {
        audit: updateObject(state.audit, {
          open: false,
          initData: initialState.audit.initData,
        }),
      });
    }
    case types.HIDE_CONTRACT_DIALOG: {
      return updateObject(state, {
        contract: updateObject(state.contract, {
          open: false,
          initData: initialState.audit.initData,
        }),
      });
    }
    // Reset
    case types.RESET: {
      return initialState;
    }
    // Default
    default: {
      return state;
    }
  }
};

export default reducer;
