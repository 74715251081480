// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Cronos = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="-89 -89 2400.000000 2400.000000">
      <g transform="translate(0.000000,2311.000000) scale(0.100000,-0.100000)">
        <path
          d="M11533 23100 c-11 -5 -351 -200 -755 -433 -4467 -2584 -7331 -4239
-8430 -4873 l-797 -459 2 -5780 2 -5779 4990 -2883 c2745 -1585 4995 -2885
5002 -2888 7 -4 214 110 460 253 246 142 1816 1050 3488 2017 1672 967 3364
1945 3760 2175 396 229 1074 621 1507 871 l787 454 -2 5780 -2 5780 -4994
2887 c-2746 1589 -4994 2888 -4995 2887 0 0 -11 -4 -23 -9z m3542 -5452 l3515
-2032 0 -4061 0 -4061 -3517 -2029 c-1934 -1115 -3522 -2028 -3528 -2029 -7
-1 -1592 912 -3523 2029 l-3512 2029 0 4061 0 4061 3523 2032 c1937 1117 3523
2032 3525 2032 1 0 1584 -914 3517 -2032z"
        />
        <path
          d="M9220 15601 l-2335 -1346 0 -2700 0 -2700 2335 -1346 2335 -1347 60
33 c187 103 4590 2655 4592 2662 1 4 -163 103 -365 219 -202 117 -634 366
-960 554 l-592 342 -1366 -791 -1365 -790 -1362 788 -1362 789 -2 1579 -2
1578 1340 780 c737 429 1348 784 1357 788 13 6 366 -194 1381 -782 l1364 -790
47 27 c601 345 1889 1099 1887 1105 -2 7 -4404 2558 -4592 2662 l-60 33 -2335
-1347z"
        />
      </g>
    </SvgIcon>
  );
};
