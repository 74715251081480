// Create
export const CREATE_SUBSCRIPTION_START = 'subscriptions/createStart';
export const CREATE_SUBSCRIPTION_SUCCESS = 'subscriptions/createSuccess';
export const CREATE_SUBSCRIPTION_FAILURE = 'subscriptions/createFailure';

// Get
export const GET_SUBSCRIPTION_PLANS_START = 'subscriptions/getPlansStart';
export const GET_SUBSCRIPTION_PLANS_SUCCESS = 'subscriptions/getPlansSuccess';
export const GET_SUBSCRIPTION_PLANS_FAILURE = 'subscriptions/getPlansFailure';

export const GET_SUBSCRIPTIONS_START = 'subscriptions/getStart';
export const GET_SUBSCRIPTIONS_SUCCESS = 'subscriptions/getSuccess';
export const GET_SUBSCRIPTIONS_FAILURE = 'subscriptions/getFailure';

// Rest
export const RESET = 'subscriptions/reset';
