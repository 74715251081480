import * as types from './types';

// Create
export const createSubscriptionStart = () => ({
  type: types.CREATE_SUBSCRIPTION_START,
});

export const createSubscriptionSuccess = (data) => ({
  type: types.CREATE_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const createSubscriptionFailure = (error) => ({
  type: types.CREATE_SUBSCRIPTION_FAILURE,
  payload: error,
});

// Get Subscription Plans
export const getSubscriptionPlansStart = () => ({
  type: types.GET_SUBSCRIPTION_PLANS_START,
});

export const getSubscriptionPlansSuccess = (data) => ({
  type: types.GET_SUBSCRIPTION_PLANS_SUCCESS,
  payload: data,
});

export const getSubscriptionPlansFailure = (error) => ({
  type: types.GET_SUBSCRIPTION_PLANS_FAILURE,
  payload: error,
});

// Get Subscriptions
export const getSubscriptionsStart = () => ({
  type: types.GET_SUBSCRIPTIONS_START,
});

export const getSubscriptionsSuccess = (data) => ({
  type: types.GET_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});

export const getSubscriptionsFailure = (error) => ({
  type: types.GET_SUBSCRIPTIONS_FAILURE,
  payload: error,
});

// Reset
export const reset = () => ({
  type: types.RESET,
});
