// Components
import LogoRaw, { logoStyler } from '../LogoRaw';

// Utils
import { makeStyles, withStyles } from '../../utils';

export const stylesCreator = (theme) => ({
  Default: {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 64,
    },
    appBar: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: 240,
      },
    },
    backButton: {
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginLeft: 240,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    adminChip: {
      marginRight: theme.spacing(1),
    },
    actionButton: {
      right: theme.spacing(1),
      position: 'absolute',
    },
    toolbar: {
      position: 'absolute',
      left: 0,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(0.5),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    link: {
      textDecoration: 'none',
    },
  },
  LogoRaw: {
    ...logoStyler(theme).Default,
    logo: {
      ...logoStyler(theme).Default.logo,
      fontSize: 26,
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(4),
      },
    },
  },
});

// Local
export const useStyles = makeStyles(stylesCreator);

// HOCs
export const Logo = withStyles(stylesCreator, LogoRaw);
