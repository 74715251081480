import * as types from './types';

export const getReportStart = (address) => ({
  type: types.GET_REPORT_START,
  payload: address,
});

export const getReportSuccess = (data) => ({
  type: types.GET_REPORT_SUCCESS,
  payload: data,
});

export const getReportFailure = (error) => ({
  type: types.GET_REPORT_FAILURE,
  payload: error,
});

export const getReportUrlStart = (address) => ({
  type: types.GET_REPORT_URL_START,
  payload: address,
});

export const getReportUrlSuccess = (data) => ({
  type: types.GET_REPORT_URL_SUCCESS,
  payload: data,
});

export const getReportUrlFailure = (error) => ({
  type: types.GET_REPORT_URL_FAILURE,
  payload: error,
});

// Reset
export const reset = () => ({
  type: types.RESET,
});

export const resetError = () => ({
  type: types.RESET_ERROR,
});
