// Types
import * as types from '../actions/audits/types';

// Shared
import { updateObject } from '../../utils';

// State
const initialState = {
  data: [],
  count: 0,
  pagination: {},
  error: null,
  isBusy: false,
  isInit: false,
  noResults: false,
  createSuccess: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Get
    case types.GET_AUDITS_START: {
      return updateObject(state, {
        error: null,
        isBusy: true,
      });
    }
    case types.GET_AUDITS_SUCCESS: {
      return updateObject(state, {
        data: action.payload.data,
        count: action.payload.count,
        pagination: { ...action.payload.pagination },
        isBusy: false,
        isInit: true,
      });
    }
    case types.GET_AUDITS_FAILURE: {
      return updateObject(state, {
        error: action.payload,
        isBusy: false,
        isInit: true,
      });
    }
    // Create
    case types.CREATE_AUDIT_START: {
      return updateObject(state, {
        error: null,
        isBusy: true,
      });
    }
    case types.CREATE_AUDIT_SUCCESS: {
      const newData = [
        action.payload,
        ...state.data.filter((audit) => audit._id !== action.payload._id),
      ];
      return updateObject(state, {
        data: newData,
        count: newData.length,
        isBusy: false,
        createSuccess: true,
      });
    }
    case types.CREATE_AUDIT_FAILURE: {
      return updateObject(state, {
        error: action.payload,
        isBusy: false,
        createSuccess: false,
      });
    }
    // Reset
    case types.RESET: {
      return initialState;
    }

    case types.RESET_CREATE_SUCCESS: {
      return updateObject(state, {
        createSuccess: false,
      });
    }
    case types.RESET_ERROR: {
      return updateObject(state, {
        error: null,
      });
    }

    // Default
    default: {
      return state;
    }
  }
};

export default reducer;
