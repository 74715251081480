import React from 'react';

import { useHistory } from 'react-router-dom';

import { Grid, Link, ListItem, Typography } from '@material-ui/core';

import BlockchainIcon from '../../BlockchainIcon/BlockchainIcon';

import { useStyles } from './TopListItem-styles';

import { annotateSecurityScore, titleShortener } from '../../../utils/common';

const TopListItem = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <ListItem divider className={classes.root}>
      <Typography className={classes.title}>
        <BlockchainIcon symbol={data.summary.blockchain} />
        <Link className={classes.link} onClick={() => history.push('/report/' + data._id)}>
          {titleShortener('reportId', data._id)}
        </Link>
      </Typography>
      <Grid
        container
        justifyContent="space-around"
        direction="row"
        className={classes.gridContainer}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.gridItemTitle} variant="body2" gutterBottom>
            Token Name
          </Typography>
          <Typography
            className={classes.gridItemText}
            variant="body2"
            color="textSecondary"
            gutterBottom>
            {data.summary.name || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.gridItemTitle} variant="body2" gutterBottom>
            Security Score
          </Typography>
          <Typography
            className={classes.gridItemText}
            variant="body2"
            color="textSecondary"
            gutterBottom>
            {annotateSecurityScore(data.summary.securityScore)}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default TopListItem;
