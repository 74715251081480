// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Arbitrum = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="0 0 2500.000000 2500.000000">
      <g transform="translate(0.000000,2500.000000) scale(0.100000,-0.100000)">
        <path
          d="M12405 23442 c-45 -16 -9184 -5286 -9278 -5350 -55 -37 -112 -121
-131 -195 -16 -57 -17 -469 -14 -5422 l3 -5360 32 -65 c17 -36 47 -79 65 -97
44 -42 1458 -858 1468 -847 4 5 1008 2752 2230 6104 1222 3352 2231 6113 2242
6134 32 63 120 143 195 179 l68 32 1245 5 c1005 4 1252 3 1280 -8 73 -27 123
-104 123 -187 -1 -41 -436 -1242 -2482 -6850 -2766 -7578 -3019 -8274 -3023
-8294 -2 -9 165 -110 472 -286 l475 -273 31 86 c499 1376 5673 15543 5689
15580 33 73 119 155 202 195 l68 32 1240 5 c1001 4 1247 3 1275 -8 73 -27 123
-104 123 -187 -1 -41 -498 -1415 -2837 -7829 -1559 -4279 -2834 -7781 -2833
-7782 36 -28 2038 -1174 2072 -1186 64 -22 126 -22 190 0 40 15 2051 1167
2078 1191 4 4 -339 958 -763 2121 -423 1162 -775 2140 -781 2172 -26 131 -49
59 615 1882 402 1101 625 1701 642 1724 77 101 254 94 325 -13 16 -23 528
-1414 1201 -3262 645 -1773 1177 -3223 1181 -3223 11 0 929 529 943 544 9 8
-357 1020 -1417 3926 -786 2153 -1437 3944 -1445 3980 -36 146 -71 35 610
1904 401 1101 625 1701 642 1724 73 95 245 95 318 -1 15 -19 31 -48 35 -63 10
-35 3672 -10068 3676 -10072 4 -4 1359 776 1418 816 55 37 112 121 131 195 16
57 17 468 14 5417 l-3 5355 -32 65 c-17 36 -47 79 -65 97 -42 40 -9255 5361
-9323 5384 -82 28 -134 31 -190 11z"
        />
      </g>
    </SvgIcon>
  );
};
