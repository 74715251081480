// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Moonriver = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="-120 -100 1300.000000 1600.000000">
      <g transform="translate(0.000000,1563.000000) scale(0.100000,-0.100000)">
        <path
          d="M5692 15619 c-550 -35 -982 -114 -1417 -261 -626 -210 -1071 -437
-1627 -826 -313 -220 -485 -368 -798 -689 -290 -297 -420 -459 -665 -832 -273
-416 -374 -608 -539 -1023 -233 -589 -329 -946 -372 -1378 -40 -417 -32 -1730
11 -1800 11 -18 41 -10 131 34 109 53 264 144 494 292 221 141 387 234 615
345 553 268 989 386 1540 419 431 25 952 2 1275 -56 316 -57 789 -217 1145
-388 172 -83 362 -202 945 -591 478 -320 628 -416 830 -533 160 -93 441 -233
565 -283 479 -190 1031 -273 1658 -248 615 24 1088 147 1657 430 272 135 479
278 570 391 113 142 139 291 161 910 19 556 -18 1123 -97 1478 -134 598 -402
1298 -662 1730 -153 254 -342 525 -496 710 -414 499 -829 881 -1305 1199 -259
174 -769 451 -1001 543 -271 108 -733 247 -1020 307 -505 105 -1113 151 -1598
120z"
        />
        <path
          d="M3155 9060 c-381 -24 -741 -81 -998 -159 -218 -66 -524 -194 -820
-342 -209 -105 -329 -175 -562 -327 -480 -314 -775 -585 -775 -712 0 -76 69
-184 152 -236 21 -13 63 -31 93 -39 48 -12 62 -12 112 1 119 30 431 199 733
395 345 225 756 452 976 540 197 78 614 164 1019 211 149 17 757 17 940 0 328
-31 702 -110 986 -208 200 -69 559 -279 1271 -745 655 -429 1174 -735 1408
-832 258 -107 833 -238 1285 -292 450 -55 924 -12 1520 136 363 90 525 148
800 289 423 216 772 445 947 622 76 77 84 100 75 207 -14 167 -140 301 -282
301 -97 0 -270 -79 -515 -235 -261 -166 -561 -318 -865 -438 -278 -109 -497
-168 -745 -199 -146 -18 -955 -18 -1100 0 -388 49 -698 155 -1145 390 -334
176 -566 317 -1075 652 -860 566 -1017 652 -1475 800 -375 121 -618 168 -1045
205 -213 19 -724 27 -915 15z"
        />
        <path
          d="M3788 7617 c-70 -27 -129 -68 -143 -100 -55 -135 -73 -750 -81 -2787
-8 -1933 14 -4101 45 -4483 8 -96 9 -99 47 -138 21 -22 72 -56 111 -76 68 -34
77 -36 138 -30 82 8 176 49 215 93 76 88 95 290 110 1179 12 753 12 4058 0
4915 -16 1089 -35 1310 -119 1367 -88 59 -246 88 -323 60z"
        />
        <path
          d="M2305 7533 c-68 -25 -115 -59 -131 -98 -57 -137 -75 -2859 -24 -3575
17 -235 36 -336 69 -374 25 -28 104 -65 178 -82 118 -27 193 -4 276 85 60 64
82 113 101 222 45 260 60 683 60 1714 0 994 -16 1535 -55 1829 -17 131 -71
225 -153 269 -58 30 -247 36 -321 10z"
        />
        <path
          d="M5216 7051 c-121 -49 -147 -87 -171 -250 -32 -219 -40 -468 -40
-1326 0 -583 4 -910 13 -1040 13 -192 33 -349 52 -417 21 -72 141 -140 250
-141 73 -1 146 37 221 113 122 125 151 258 169 770 12 346 12 1082 0 1431 -20
575 -61 706 -259 838 -46 30 -63 36 -120 38 -47 2 -80 -2 -115 -16z"
        />
        <path
          d="M883 6806 c-116 -54 -133 -81 -157 -247 -33 -222 -41 -488 -41 -1334
1 -1097 21 -1420 96 -1501 29 -31 116 -72 182 -85 51 -10 64 -9 123 10 77 25
159 75 196 119 53 63 80 171 99 397 16 188 23 1583 10 1910 -20 489 -48 579
-214 682 -125 79 -202 92 -294 49z"
        />
        <path
          d="M6601 6168 c-106 -30 -128 -88 -152 -388 -14 -173 -6 -485 15 -585
27 -131 53 -184 126 -256 36 -36 87 -75 111 -87 43 -21 48 -21 88 -8 49 18
167 93 198 127 56 61 82 177 83 361 0 137 -29 811 -36 817 -9 9 -236 30 -319
30 -44 -1 -95 -5 -114 -11z"
        />
        <path
          d="M10960 5844 c-95 -44 -117 -67 -140 -142 -40 -138 -60 -402 -60 -827
0 -415 20 -695 61 -840 24 -85 144 -157 264 -159 111 -2 262 89 312 187 55
110 83 384 83 822 -1 407 -23 648 -71 751 -33 71 -114 145 -210 193 -100 50
-156 54 -239 15z"
        />
        <path
          d="M8152 5695 c-53 -23 -172 -104 -203 -137 -100 -110 -113 -394 -106
-2243 5 -1294 9 -1437 43 -1609 18 -95 41 -143 88 -184 43 -37 158 -105 207
-122 33 -12 40 -11 88 15 29 16 89 64 135 106 130 122 153 192 178 535 18 255
18 2742 0 3000 -15 204 -39 339 -72 405 -45 87 -240 250 -299 249 -14 -1 -41
-7 -59 -15z"
        />
        <path
          d="M9545 5514 c-133 -68 -155 -94 -179 -211 -37 -175 -41 -334 -41
-1658 0 -1299 3 -1441 37 -1600 22 -104 68 -158 168 -196 81 -31 161 -26 247
15 116 56 142 116 164 388 16 198 23 2045 10 2503 -15 510 -33 623 -110 688
-35 29 -191 107 -213 107 -7 0 -44 -17 -83 -36z"
        />
      </g>
    </SvgIcon>
  );
};
