export const stylesCreator = (theme) => ({
  Default: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    tooltipIcon: {
      fontSize: '0.875rem',
    },
  },
});
