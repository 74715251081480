// React
import React from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Material
import { Brightness4 as DarkModeIcon } from '@material-ui/icons';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Switch,
} from '@material-ui/core';

// Store
import { setThemeMode } from '../../../../store/actions';

// Styles
import { useStyles } from './MenuTheme-styles';

const MenuTheme = () => {
  // Variables
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDark = useSelector((state) => state.theme.palette.type === 'dark');

  // Handlers
  const toggleDarkModeHandler = (event) => {
    if (event.target.checked) {
      dispatch(setThemeMode('dark'));
    } else {
      dispatch(setThemeMode('light'));
    }
  };

  //JSX
  const header = <ListSubheader className={classes.header}>Theme</ListSubheader>;

  const darkMode = (
    <ListItem>
      <ListItemIcon>
        <DarkModeIcon />
      </ListItemIcon>
      <ListItemText primary="Dark Mode" />
      <ListItemSecondaryAction>
        <Switch color="primary" edge="end" onChange={toggleDarkModeHandler} checked={isDark} />
      </ListItemSecondaryAction>
    </ListItem>
  );

  const view = (
    <div className={classes.root}>
      <List className={classes.list} subheader={header}>
        {darkMode}
      </List>
    </div>
  );

  return view;
};

export default MenuTheme;
