// React
import React, { useState } from 'react';

// Router
import { useHistory, useLocation } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Material
import {
  AccountCircle as AccountIcon,
  ArrowBack as BackIcon,
  Menu as MenuIcon,
} from '@material-ui/icons';
import { AppBar, Button, Chip, IconButton, Toolbar } from '@material-ui/core';

// Components
import Menu from './Menu';

// Styles
import { useStyles } from './Header-styles';

const Header = () => {
  // Variables
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const isAuth = useSelector((state) => state.auth.data.token !== null);
  const user = useSelector((state) => state.user.data);
  const showBackButton = location.pathname.includes('/report/');
  const showSignIn =
    !location.pathname.includes('/signin') && !location.pathname.includes('/signup');

  // Handlers
  const closeMenuHandler = () => {
    setOpen(false);
  };

  // JSX
  const view = (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="relative" color="transparent" elevation={0}>
        <Toolbar disableGutters className={classes.toolbar}>
          <IconButton
            onClick={() => setOpen(true)}
            edge="start"
            aria-label="menu"
            className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
        </Toolbar>

        {showBackButton ? (
          <div className={classes.backButton}>
            <IconButton onClick={() => history.goBack()}>
              <BackIcon />
            </IconButton>
          </div>
        ) : null}

        {isAuth ? (
          <div className={classes.actionButton}>
            {user.isAdmin ? (
              <Chip className={classes.adminChip} label="Admin" variant="outlined" size="small" />
            ) : null}
            <IconButton onClick={() => history.push('/account')}>
              <AccountIcon />
            </IconButton>
          </div>
        ) : showSignIn ? (
          <div className={classes.actionButton} onClick={() => history.push('/signin')}>
            <Button startIcon={<AccountIcon />}>Sign In</Button>
          </div>
        ) : null}
      </AppBar>
      <Menu open={open} onClose={closeMenuHandler} isAuth={isAuth} />
    </div>
  );

  return view;
};

export default Header;
