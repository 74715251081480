// Axios
import axios from 'axios';

// React Redux
import { batch } from 'react-redux';

// Lodash
import _ from 'lodash';

// Types
import * as creators from './creators';

// Actions
import { showAlert } from '../../actions';

export const getReport = (reportId) => {
  return (dispatch) => {
    dispatch(creators.getReportStart({ reportId }));
    const url = '/reports/' + reportId;

    axios
      .get(url)
      .then((response) => {
        dispatch(creators.getReportSuccess(response.data));
      })
      .catch((error) => {
        batch(() => {
          dispatch(creators.getReportFailure(error));
          dispatch(showAlert(_.get(error, 'response.data.error')));
        });
      });
  };
};

export const downloadAuditReport = (reportUrl, fileName = 'report.pdf') => {
  axios
    .get(reportUrl, { responseType: 'blob' })
    .then((response) => {
      const anchorEl = document.createElement('a');
      anchorEl.href = window.URL.createObjectURL(response.data);

      anchorEl.download = fileName;
      document.body.appendChild(anchorEl);
      anchorEl.click();
      anchorEl.remove();
    })
    .catch((error) => {
      throw error;
    });
};

export const getReportUrl = (reportId) => {
  return (dispatch) => {
    dispatch(creators.getReportUrlStart({ reportId }));
    const url = '/reports/' + reportId + '/pdf';

    axios
      .post(url)
      .then((response) => {
        dispatch(creators.getReportUrlSuccess(response.data.data.reportUrl));
        downloadAuditReport(response.data.data.reportUrl, `${reportId}.pdf`);
      })
      .catch((error) => {
        batch(() => {
          dispatch(creators.getReportUrlFailure(error));
          dispatch(showAlert(_.get(error, 'response.data.error')));
        });
      });
  };
};
