// React
import React from 'react';
import ReactDOM from 'react-dom';

// Router
import { BrowserRouter } from 'react-router-dom';

// Axios
import axios from 'axios';

// Redux
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

// Redux-Thunk
import thunk from 'redux-thunk';

// Analytics
import AnalyticsProvider from './components/Analytics';

// Reducers
import auditsReducer from './store/reducers/audits';
import authReducer from './store/reducers/auth';
import computedDataReducer from './store/reducers/computedData';
import dialogReducer from './store/reducers/dialog';
import reportReducer from './store/reducers/report';
import searchReducer from './store/reducers/search';
import subscriptionsReducer from './store/reducers/subscriptions';
import themeReducer from './store/reducers/theme';
import toastReducer from './store/reducers/toast';
import userReducer from './store/reducers/user';

// App
import App from './components/App';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

let composeEnhancers = compose;
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const rootReducer = combineReducers({
  auth: authReducer,
  dialog: dialogReducer,
  computedData: computedDataReducer,
  subscriptions: subscriptionsReducer,
  theme: themeReducer,
  toast: toastReducer,
  user: userReducer,
  search: searchReducer,
  audits: auditsReducer,
  report: reportReducer,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AnalyticsProvider devOptions={{ logToConsole: true }}>
          <App />
        </AnalyticsProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
