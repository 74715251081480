// React
import React from 'react';

// Material
import { SvgIcon } from '@material-ui/core';

export const Gnosis = (props) => {
  // Variables
  const { color, fontSize } = props;

  // JSX
  return (
    <SvgIcon color={color} fontSize={fontSize} viewBox="-100 -100 2100.000000 2100.000000">
      <g transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)">
        <path
          d="M9565 19989 c-1549 -68 -3063 -494 -4403 -1238 -581 -322 -1158 -723
-1657 -1150 -476 -408 -964 -916 -1359 -1414 l-78 -98 371 -377 c203 -207
1988 -1995 3965 -3972 l3596 -3595 3965 3965 c2181 2181 3965 3969 3965 3973
0 5 -51 72 -113 151 -526 660 -1188 1299 -1867 1803 -711 528 -1549 988 -2375
1304 -1263 482 -2662 708 -4010 648z m890 -1309 c2126 -115 4054 -949 5563
-2406 l133 -129 -3075 -3075 -3076 -3075 -3075 3075 c-2561 2561 -3072 3077
-3062 3089 28 34 354 335 477 442 736 632 1547 1125 2432 1478 893 355 1836
557 2833 604 155 8 689 6 850 -3z"
        />
        <path
          d="M1318 14957 c-1155 -2024 -1563 -4347 -1172 -6661 253 -1496 876
-2964 1783 -4199 1140 -1552 2690 -2741 4476 -3430 1896 -731 4008 -864 5990
-377 1999 492 3800 1594 5160 3160 735 845 1326 1818 1747 2875 90 227 245
682 313 920 362 1268 472 2643 314 3950 -165 1369 -604 2668 -1312 3883 l-60
104 -805 -805 c-755 -755 -805 -807 -791 -825 8 -11 49 -65 92 -120 588 -765
848 -1776 707 -2747 -186 -1274 -1025 -2356 -2216 -2858 -320 -135 -680 -226
-1059 -269 -166 -19 -589 -15 -765 6 -682 82 -1296 329 -1824 735 -54 42 -105
80 -112 86 -11 8 -217 -193 -899 -875 l-885 -885 -883 883 -883 883 -65 -51
c-331 -265 -738 -485 -1149 -620 -217 -72 -480 -128 -750 -162 -163 -20 -651
-17 -825 5 -848 109 -1569 460 -2160 1052 -581 580 -933 1294 -1051 2125 -21
154 -30 619 -15 788 65 722 342 1416 785 1968 l55 69 -802 805 c-441 443 -804
806 -807 807 -3 2 -62 -97 -132 -220z"
        />
        <path
          d="M3777 12739 c-133 -186 -266 -438 -342 -643 -109 -295 -163 -644
-152 -971 33 -916 523 -1727 1314 -2175 184 -104 423 -199 637 -255 706 -182
1471 -53 2077 350 69 46 131 91 139 100 12 15 -180 210 -1807 1837 l-1820
1820 -46 -63z"
        />
        <path
          d="M14355 10979 l-1820 -1820 25 -20 c142 -114 445 -280 655 -360 134
-50 369 -111 525 -135 166 -27 586 -27 755 0 437 67 850 240 1189 496 385 291
686 690 859 1140 77 199 120 369 153 595 24 165 25 514 0 680 -60 416 -193
766 -421 1111 -40 60 -80 115 -87 122 -11 9 -373 -348 -1833 -1809z"
        />
      </g>
    </SvgIcon>
  );
};
