// Types
import * as types from '../actions/subscriptions/types';

// Shared
import { updateObject } from '../../utils';

// State
const initialState = {
  plans: {
    data: [],
    error: null,
    isBusy: false,
    isInit: false,
  },
  data: [],
  count: 0,
  error: null,
  isBusy: false,
  isInit: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Create
    case types.CREATE_SUBSCRIPTION_START: {
      return updateObject(state, {
        error: null,
        isBusy: true,
      });
    }
    case types.CREATE_SUBSCRIPTION_SUCCESS: {
      const newData = [
        action.payload,
        ...state.data.filter((subscription) => subscription._id !== action.payload._id),
      ];
      return updateObject(state, {
        data: newData,
        count: newData.length,
        isBusy: false,
      });
    }
    case types.CREATE_SUBSCRIPTION_FAILURE: {
      return updateObject(state, {
        error: action.payload,
        isBusy: false,
      });
    }
    // Get Subscription Plans
    case types.GET_SUBSCRIPTION_PLANS_START: {
      return updateObject(state, {
        plans: updateObject(state.plans, {
          error: null,
          isBusy: true,
        }),
      });
    }
    case types.GET_SUBSCRIPTION_PLANS_SUCCESS: {
      return updateObject(state, {
        plans: updateObject(state.plans, {
          data: action.payload,
          isBusy: false,
          isInit: true,
        }),
      });
    }
    case types.GET_SUBSCRIPTION_PLANS_FAILURE: {
      return updateObject(state, {
        plans: updateObject(state.plans, {
          error: action.payload,
          isBusy: false,
          isInit: true,
        }),
      });
    }
    // Get Subscriptions
    case types.GET_SUBSCRIPTIONS_START: {
      return updateObject(state, {
        error: null,
        isBusy: true,
      });
    }
    case types.GET_SUBSCRIPTIONS_SUCCESS: {
      return updateObject(state, {
        data: action.payload.data,
        count: action.payload.count,
        isBusy: false,
        isInit: true,
      });
    }
    case types.GET_SUBSCRIPTIONS_FAILURE: {
      return updateObject(state, {
        error: action.payload,
        isBusy: false,
        isInit: true,
      });
    }
    // Reset
    case types.RESET: {
      return initialState;
    }
    // Default
    default: {
      return state;
    }
  }
};

export default reducer;
