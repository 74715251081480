// Types
import * as types from './types';

export const getComputedDataStart = () => ({
  type: types.GET_COMPUTED_DATA_START,
});

export const getComputedDataSuccess = (data) => ({
  type: types.GET_COMPUTED_DATA_SUCCESS,
  payload: data,
});

export const getComputedDataFailure = (error) => ({
  type: types.GET_COMPUTED_DATA_FAILURE,
  payload: error,
});

// Reset
export const reset = () => ({
  type: types.RESET,
});
