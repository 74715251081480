// Get
export const GET_RESULTS_START = 'search/getResultsStart';
export const GET_RESULTS_SUCCESS = 'search/getResultsSuccess';
export const GET_RESULTS_FAILURE = 'search/getResultsFailure';

export const GET_MAIN_PAGE_RESULTS_START = 'search/getMainPageResultsStart';
export const GET_MAIN_PAGE_RESULTS_SUCCESS = 'search/getMainPageResultsSuccess';
export const GET_MAIN_PAGE_RESULTS_FAILURE = 'search/getMainPageResultsFailure';

// SET
export const SET_FILTER = 'search/setFilter';

// Reset
export const RESET = 'search/reset';
export const RESET_FILTER = 'search/resetFilter';
